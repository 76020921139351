import search from '../attachments/Search.png';
import phone from '../attachments/phone.png';
import Cleanser from "../attachments/cleanser.svg";
import Facewash from "../attachments/facewash.svg";
import Toner from "../attachments/toner.svg";
import MoisturizerIc from "../attachments/moisturizer.svg";
import tonerCICA from '../attachments/catalogues/tonerCICA.jpeg';
import faceSerum from '../attachments/catalogues/faceSerum.jpeg';
import retinolSerum from "../attachments/catalogues/retinolB3Serum.jpeg";
import grayFaceWash from "../attachments/catalogues/grayFaceWash.jpeg";
import ScarlettWhiteningLotion from '../attachments/catalogues/Lotions/ScarlettWhiteningLotion.jpeg';
import FameuxLotion from '../attachments/catalogues/Lotions/fameuxLotion.jpeg';
import BonavieBodyLotion from '../attachments/catalogues/Lotions/bonavieBodyLotion.jpeg';
import Moisturizer from '../attachments/catalogues/antiAgingMoisturizer.jpeg';
import clayMask from '../attachments/catalogues/clayMask.jpeg';
import lowPHcleanser from '../attachments/catalogues/lowPHcleanser.jpeg';
import greenFaceWash from '../attachments/catalogues/greenFacialWash.jpeg';
import bannerMid from '../attachments/banner-midder.jpg';
import highlight1 from '../attachments/catalogues/Perfumes/1.jpg';
import highlight2 from '../attachments/catalogues/Perfumes/3.jpg';
import highlight3 from '../attachments/catalogues/Perfumes/6.jpg';
import highlight4 from "../attachments/catalogues/Perfumes/2.jpg";
import highlight5 from "../attachments/catalogues/Perfumes/4.jpg";
import highlight6 from "../attachments/catalogues/Perfumes/5.jpg";


import avtr1 from '../attachments/avtr1.png';
import avtr2 from "../attachments/avtr2.png";
import avtr3 from "../attachments/avtr3.png";

import truckIcon from '../attachments/truckIcon.png';
import testCheck from '../attachments/testCheck.png';
import checkShield from '../attachments/checkShield.png';
import customerSuppIcon from '../attachments/customerSuppIcon.png';
import { FaEnvelope, FaInstagram, FaLocationArrow, FaPhone, FaTiktok, FaWhatsapp } from 'react-icons/fa';
// import { FaMessage } from 'react-icons/fa6';

const Database = {
  navbar: {
    menu1: [
      {
        title: "Beranda",
        link: "/",
      },
    ],

    menu2: [
      {
        title: "Toko",
        link: "Shop",
      },
    ],

    icons: {
      icon1: {
        icon: <FaInstagram />,
        link: "https://www.instagram.com/ftbw.cosmetic?igsh=Mms0cjlqbHRxcGQy",
      },
      icon2: {
        icon: <FaTiktok />,
        link: "https://www.tiktok.com/@ftbw_cosmetic?_t=8l4XpURPoS9&_r=1",
      },
    },
  },

  home: {
    title: "INSTANT FACE LIFT",
    subtitle: "SAFELY WHITEN YOUR SKIN",
    desc: `Archive your beautiful skin and attract
people’s eyes that easy.`,
    button: {
      title: "Shop Now!",
      link: "Shop",
    },
  },

  ShopCat: {
    title: "FEATURED PRODUCTS",
    header: "The Best Products Of FTBW-Beauty",
    menus: [
      {
        icon: Toner,
        title: "Toner / Serum",
        goto: "toner",
      },

      {
        icon: Facewash,
        title: "Facewash / Mask",
        goto: "facewash",
      },

      {
        icon: Cleanser,
        title: "Body Lotion",
        goto: "bodylotion",
      },

      {
        icon: MoisturizerIc,
        title: "Moisturizer",
        goto: "moisturizer",
      },
    ],

    catalogue1: [
      {
        photo: tonerCICA,
        rates: 4,
        title: "NPURE Toner Cica 30 ml",
        price: "25,600",
        label: "New!",
        category: "toner",
        link: "https://shopee.co.id/NPURE-TONER-CICA-30-ML-i.115276607.18778362350?sp_atk=eedc65c8-9528-4b31-88e5-d2ab61c86959&xptdk=eedc65c8-9528-4b31-88e5-d2ab61c86959",
      },
      {
        photo: faceSerum,
        rates: 4,
        title: "Glad2glow Face Serum",
        price: "79,200",
        label: "",
        category: "toner",
        link: "https://shopee.co.id/Glad2Glow-Milk-Amino-Acid-Gentle-Cleanser-Face-Wash-80ml-Sabun-Cuci-Muka-Facial-Wash-80ml-Face-Cleansing-i.809769142.20229171522?sp_atk=3f396dae-525f-4405-a39a-887115a91a68&xptdk=3f396dae-525f-4405-a39a-887115a91a68",
      },
      {
        photo: retinolSerum,
        rates: 5,
        title: "The Originote Retinol B3 Serum",
        price: "128,923",
        label: "",
        category: "toner",
        link: "https://shopee.co.id/The-Originote-Retinol-B3-Serum-Serum-Anti-Aging-Menyamarkan-Garis-Halus-Meratakan-Warna-Kulit-Merawat-Kulit-Berjerawat-with-Vitamin-A-Niacinamide-i.710619388.15095263644?sp_atk=1bf4801d-8531-41d5-a31f-fb058bfd0c89&xptdk=1bf4801d-8531-41d5-a31f-fb058bfd0c89",
      },
      {
        photo: grayFaceWash,
        rates: 0,
        title: "Glad2Glow Milk Amino Acid Gentle Cleanser Face Wash 80ml",
        price: "52,650",
        label: "",
        category: "facewash",
        link: "https://shopee.co.id/Glad2Glow-Milk-Amino-Acid-Gentle-Cleanser-Face-Wash-80ml-Sabun-Cuci-Muka-Facial-Wash-80ml-Face-Cleansing-i.809769142.20229171522?sp_atk=3f396dae-525f-4405-a39a-887115a91a68&xptdk=3f396dae-525f-4405-a39a-887115a91a68",
      },
      {
        photo: Moisturizer,
        rates: 4,
        title: "NPURE Marigold Anti-aging youth defense Moisturizer",
        price: "175,500",
        label: "New!",
        category: "moisturizer",
        link: "https://shopee.co.id/NPURE-MARIGOLD-ANTI-AGING-YOUTH-DEFENSE-MOISTURIZER-i.115276607.20220372326?sp_atk=2b152d11-ee48-4057-8298-15c07eefb9b2&xptdk=2b152d11-ee48-4057-8298-15c07eefb9b2",
      },
      {
        photo: clayMask,
        rates: 4,
        title: "Glad2Glow Mugwort Clay Mask 25 gr",
        price: "51,299",
        label: "",
        category: "facewash",
        link: "https://shopee.co.id/Glad2Glow-Mugwort-Clay-Mask-Masker-Wajah-Face-Cleansing-Green-Mask-Stick-Salicylic-Acid-Anti-Pores-Acne-Jerawat-Komedo-Cleansing-Masker-25g-Centella-Masker-Wajah-Mud-Mask-Facial-Mask-Wash-Off-Mask-Mugwort-Acne-i.809769142.22873282452?sp_atk=bee4cd07-f4e2-405c-9c92-2a330a63e3f0&xptdk=bee4cd07-f4e2-405c-9c92-2a330a63e3f0",
      },
      {
        photo: lowPHcleanser,
        rates: 5,
        title: "SCORA 1% Salicylic Acid Gentle Low pH Cleanser",
        price: "51,299",
        label: "",
        category: "facewash",
        link: "https://shopee.co.id/-PO-7-HARI-SCORA-1-Salicylic-Acid-Gentle-Low-pH-Cleanser-Sabun-Cuci-Muka-Oily-Acne-Prone-Skin-Friendly-i.1112776376.25815711335?sp_atk=48774a4b-8141-4c04-9413-be0dae7983d9&xptdk=48774a4b-8141-4c04-9413-be0dae7983d9",
      },
      {
        photo: greenFaceWash,
        rates: 0,
        title:
          "AVOSKIN Gentle Facial Wash Avoskin Natural Sublime Facial Cleanser 100ml",
        price: "160,650",
        label: "New!",
        category: "facewash",
        link: "https://shopee.co.id/Gentle-Facial-Wash-Avoskin-Natural-Sublime-Facial-Cleanser-100ml-i.154494405.3765113010?sp_atk=cac1feff-a339-448b-9117-6b15c9d353f6&xptdk=cac1feff-a339-448b-9117-6b15c9d353f6",
      },
      {
        photo: ScarlettWhiteningLotion,
        rates: 5,
        title: "Scarlett Whitening Loving Body Lotion",
        price: "101,250",
        label: "New!",
        category: "bodylotion",
        link: "https://shopee.co.id/Scarlett-Whitening-Loving-Body-Lotion-i.1212420650.24220552226?sp_atk=f91969ce-ddef-43db-9fe4-01853f584396&xptdk=f91969ce-ddef-43db-9fe4-01853f584396",
      },
      {
        photo: FameuxLotion,
        rates: 4,
        title: "Fameux Whitening Lotion Pomegranate Original 250 ml",
        price: "84,645",
        label: "New!",
        category: "bodylotion",
        link: "https://shopee.co.id/Fameux-Whitening-Lotion-Pomegranate-Original-250-ml-i.1212420650.22064195394?sp_atk=c8c37de7-34cd-4781-b6d6-39636342b73b&xptdk=c8c37de7-34cd-4781-b6d6-39636342b73b",
      },
      {
        photo: BonavieBodyLotion,
        rates: 5,
        title: "Bonavie Body Lotion Marie Antoinette 250 ml",
        price: "101,790",
        label: "New!",
        category: "bodylotion",
        link: "https://shopee.co.id/Bonavie-Body-Lotion-Marie-Antoinette-250-ml-i.1212420650.25820750559?sp_atk=5b15337e-1879-4f7f-816f-934bb47d0397&xptdk=5b15337e-1879-4f7f-816f-934bb47d0397",
      },
    ],
  },

  banner: {
    bg: bannerMid,
    title: "50% OFF",
    subtitle: `FTBW-Beauty Sale! Minimum 55% or up to 80% off on a big 
range of facial treatments.`,
    button: {
      title: "Shop Now!",
      link: "Shop",
    },
  },

  Showcase: {
    menu: [
      {
        title: "Most Wanted",
        tag: "wanted",
      },
      {
        title: "New Arrival",
        tag: "arrival",
      },
      {
        title: "Best Seller",
        tag: "best",
      },
    ],
    highlight: [
      {
        img: highlight1,
        rates: 5,
        title: "Parfum Wanita |FTBW|| ROSE GLAM PARFUM | ELEGANCE 35 ML|",
        price: "110,000",
        link: "https://shopee.co.id/FTBW-ROSE-GLAM-PARFUM-ELEGANCE-35-ML-i.1212420650.26503435414?xptdk=363bf879-77d6-453e-bce3-23c144ef4261",
        tag: "wanted",
      },
      {
        img: highlight2,
        rates: 4,
        title: "Parfum Unisex JENNY LIQUID FRESH FTBW | Tahan Lama",
        price: "110,000",
        link: "https://shopee.co.id/FTBW-JENNY-LIQUID-FRESH-MUSKY-PARFUM-PRIA-DAN-WANITA-i.1212420650.27053430755?xptdk=635c4ec2-8ed6-40af-8c54-5884d1671b35",
        tag: "arrival",
      },
      {
        img: highlight3,
        rates: 5,
        title: "Parfum FTBW MANLY | PARFUM | FTBW COSMETIC | Tahan Lama",
        price: "110,000",
        link: "https://shopee.co.id/Parfum-FTBW-MANLY-PARFUM-FTBW-COSMETIC-parfum-cowok-parfum-murah-parfum-viral-parfum-tahan-lama-i.1212420650.27811580821",
        tag: "best",
      },
    ],

    grids: [
      {
        photo: highlight4,
        rates: 4,
        title: "Parfum Unisex |FTBW COSMETIC|| JISO SWEET ESCAPE PARFUME |",
        price: "110,000",
        label: "New!",
        category: "perfume",
        link: "https://shopee.co.id/FTBW-JISO-SWEET-ESCAPE-PARFUM-COCOK-UNTUK-PRIA-DAN-WANITA-i.1212420650.28553429287?xptdk=4a4ad3f9-46e5-4a35-b826-85e563b0a61a",
      },
      {
        photo: highlight5,
        rates: 4,
        title: "Parfum FTBW SEXY KIM | PARFUM | FTBW COSMETIC | Tahan Lama",
        price: "110,000",
        label: "",
        category: "perfume",
        link: "https://shopee.co.id/Parfum-FTBW-SEXY-KIM-PARFUM-FTBW-COSMETIC-Parfum-wanita-parfum-tahan-lama-parfum-murah-parfum-viral-i.1212420650.24886142208",
      },
      {
        photo: highlight6,
        rates: 4,
        title: "Parfum PLAYFUL MILEY | PARFUM | FTBW COSMETIC",
        price: "110,000",
        label: "New!",
        category: "perfume",
        link: "https://shopee.co.id/Parfum-PLAYFUL-MILEY-PARFUM-FTBW-COSMETIC-parfum-murah-parfum-viral-parfum-cewe-i.1212420650.28811523932",
      },
      {
        photo: highlight2,
        rates: 4,
        title: "Parfum Unisex JENNY LIQUID FRESH FTBW |",
        price: "110,000",
        label: "",
        category: "perfume",
        link: "https://shopee.co.id/FTBW-JENNY-LIQUID-FRESH-MUSKY-PARFUM-PRIA-DAN-WANITA-i.1212420650.27053430755?xptdk=635c4ec2-8ed6-40af-8c54-5884d1671b35",
      },
    ],
  },

  Carocase: {
    header: "Testimonial Kami",
    title: "Apa Kata Klien Kami",

    carousel: [
      {
        img: avtr1,
        title: `Sesuai seperti expertasi, 
Mantap  ✨`,
        name: "Katheryn Simona",
        position: "Product Tester",
      },
      {
        img: avtr2,
        title: `Ini adalah produk Bagus! 
Beneran cobain 😉`,
        name: "Lilywang Mantee",
        position: "Product Tester",
      },
      {
        img: avtr3,
        title: `Bekerja dengan baik pada kulit! 
Kerja bagus 👍`,
        name: "Patricia Lily",
        position: "Product Tester",
      },
    ],

    benefits: [
      {
        icon: truckIcon,
        title: "Pengiriman Pulau Jawa Gratis",
      },
      {
        icon: testCheck,
        title: "Telah Diuji Oleh Para Ahli",
      },
      {
        icon: checkShield,
        title: "3 Bulan Garansi",
      },
      {
        icon: customerSuppIcon,
        title: "24/7 Customer Support",
      },
    ],
  },

  footer: {
    contacts: [
      {
        icon: <FaEnvelope />,
        title: "Kirim email ke: ftbwcosmetic@gmail.com",
        link: "mailto:ftbwcosmetic@gmail.com",
      },
      {
        icon: <FaLocationArrow />,
        title: "Alamat: Jakarta Timur, Jawa Barat, ID",
        link: "https://maps.google.com",
      },
      {
        icon: <FaWhatsapp />,
        title: " Ngobrol dengan Kami: +62 858-1116-5429",
        link: "https://wa.me/+6285811165429",
      },
    ],
    contactTitle: "Kontak Kami",
    socialTitle: "Platform Sosial Kami",
    socials: [
      {
        icons: <FaTiktok />,
        link: "https://www.tiktok.com/@ftbw_cosmetic?_t=8l4XpURPoS9&_r=1",
      },
      {
        icons: <FaInstagram />,
        link: "https://www.instagram.com/ftbw.cosmetic?igsh=Mms0cjlqbHRxcGQy",
      },
    ],
    news: {
      title: "Langganan Berita Kami",
      form: {
        link: "mailto:ftbwcosmetic@gmail.com",
        placeholder: "Your Email",
      },
    },

    copyright: (
      <p>
        Copywrite 2023 by <strong>FTBW-Cosmetics</strong>. All Rights Reserved.
      </p>
    ),
  },
};

export default Database;